body .ui-tabview {
    padding: 0;
    border-bottom: 1px solid #c7c7c7;
}
body .ui-tabview.ui-tabview-top,
body .ui-tabview.ui-tabview-bottom,
body .ui-tabview.ui-tabview-left,
body .ui-tabview.ui-tabview-right {
    border: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav,
body .ui-tabview.ui-tabview-left .ui-tabview-nav,
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
    padding: 0;
    border: 0 none;
    background: #ffffff;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-bottom: 1px solid #cccccc;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
    position: static;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-bottom: none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-default a,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-default a,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-default a,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-default a {
    padding: 0.375em 0.75em;
    color: #2d353c;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-default a:focus,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-default a:focus,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-default a:focus,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-default a:focus {
    outline: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
    border-color: #dedede;
    border-bottom: none;
    background-color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-tabview-outline,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-outline,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-outline,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-outline {
    outline: 0 none;
    background-color: #e6e6e6;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
    background-color: #0275d8;
    border-color: #0275d8;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
    color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active.ui-tabview-outline,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active.ui-tabview-outline,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active.ui-tabview-outline,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active.ui-tabview-outline {
    background-color: #0283f1;
}
body .ui-tabview.ui-tabview-top .ui-tabview-navscroller-btn,
body .ui-tabview.ui-tabview-bottom .ui-tabview-navscroller-btn,
body .ui-tabview.ui-tabview-left .ui-tabview-navscroller-btn,
body .ui-tabview.ui-tabview-right .ui-tabview-navscroller-btn {
    background-color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-navscroller-btn:not(.ui-state-active):not(.ui-state-disabled):hover,
body .ui-tabview.ui-tabview-bottom .ui-tabview-navscroller-btn:not(.ui-state-active):not(.ui-state-disabled):hover,
body .ui-tabview.ui-tabview-left .ui-tabview-navscroller-btn:not(.ui-state-active):not(.ui-state-disabled):hover,
body .ui-tabview.ui-tabview-right .ui-tabview-navscroller-btn:not(.ui-state-active):not(.ui-state-disabled):hover {
    background-color: #f4f3f4;
}
body .ui-tabview.ui-tabview-top .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-left,
body .ui-tabview.ui-tabview-bottom .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-left,
body .ui-tabview.ui-tabview-left .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-left,
body .ui-tabview.ui-tabview-right .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-left {
    left: 0;
    height: 2.063em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-right,
body .ui-tabview.ui-tabview-bottom .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-right,
body .ui-tabview.ui-tabview-left .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-right,
body .ui-tabview.ui-tabview-right .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-right {
    right: 0;
    height: 2.125em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-navscroller-btn .ui-icon,
body .ui-tabview.ui-tabview-bottom .ui-tabview-navscroller-btn .ui-icon,
body .ui-tabview.ui-tabview-left .ui-tabview-navscroller-btn .ui-icon,
body .ui-tabview.ui-tabview-right .ui-tabview-navscroller-btn .ui-icon {
    margin-top: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
    margin-right: 0.125em;
    -moz-border-radius-topleft: 0.25em;
    -webkit-border-top-left-radius: 0.25em;
    border-top-left-radius: 0.25em;
    -moz-border-radius-topright: 0.25em;
    -webkit-border-top-right-radius: 0.25em;
    border-top-right-radius: 0.25em;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav,
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
    border-bottom: none;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
    border: 1px solid transparent;
    width: 100%;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li:hover,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li:hover {
    border: 1px solid #dedede;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
    border-color: #0275d8;
    border-bottom: none;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav {
    border-bottom: none;
    border-top: 1px solid #cccccc;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li {
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 0.125em;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:hover {
    border-bottom: 1px solid #cccccc;
    border-top-color: transparent;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav {
    border-right: 1px solid #cccccc;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li:hover {
    border-right: none;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
    border-left: 1px solid #cccccc;
    height: 9.375em;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav li:hover {
    border-left-color: transparent;
}
body .ui-tabview .ui-tabview-panel {
    padding: 0.375em 0.75em;
}

body .ui-tabview .ui-tabview-panels {
    background-color: #ffffff;
    padding: 0;
    border: 1px solid #c8c8c8;
    color: #333333;
}
body{
    .ui-paginator{
        .ui-paginator-pages {
            a.ui-paginator-page{              
                padding: 0em;               
            }
        }
    }
   
}

/* added for redesign */
.navbar { border: none; }
nav.navbar-subsite .menu-hover:hover { 
    background-color: #0B2D71; 
}
nav.navbar-subsite .icon-list li { 
    padding: 7px 25px;     
}
nav.navbar .hr-mobile-nav { 
   height:2px; 
   border:none; 
   color:#ccc; 
   background-color:#ccc;
   margin-top: 0;
}
nav.navbar-subsite .dropdown.level-1.offcanvas:nth-last-child(-n+2) > .dropdown-menu {
    left:auto;
    right:0;
}
nav.navbar-default .focus-link {
    text-align: left;
    padding-top: 30px;
    margin-top:0;
}
nav.navbar-default .focus-link a {
    padding: 15px 30px;
}
nav.navbar-default { 
    border: none;
    background-color: transparent;
    min-height: 104px;
}
nav.navbar-default .navbar-header { 
    float: left;
    text-align: center;
    width: 100%;
}
nav.navbar-default .navbar-collapse {
    margin: 0 auto;
    padding: 0;
    padding-right: 88px;
}
nav.navbar-default .navbar-nav {
    width: 50%;
    margin-left: 0;
}
nav.navbar-default .navbar-right {
    float: right;
    margin-left:0; 
    margin-right: -88px;  
}
nav.navbar-default .navbar-brand-centered,
nav.navbar-subsite .navbar-brand-centered {
    position: absolute;
    left: 50%;
    top: 19px;
    display: block;
    text-align: center;
    background-color: transparent;
    padding: 0;
    line-height: 30px;
    width:350px;
    /* margin-left must be 1/2 width */
    margin-left: -175px !important;
    min-height:88px;
}
nav.navbar-default .hallmark-container {
    min-height: 40px;
}   
nav.navbar-default .sitename-container {
    height: 40px;
    min-height: 40px;
}  
nav.navbar-default .navbar-brand-centered a.sitename,
nav.navbar-subsite .navbar-brand-centered a.sitename {
    font-size: 0.78em;
    line-height: 1.1em;
}
nav.navbar-default .dropdown-large {
    position: static !important;
    margin: 28px 12px 0;
}
nav.navbar-default .dropdown-large.search {
    margin: 24px 3px 0;
}
nav.navbar-default .dropdown-large.search .dropdown-toggle .glyphicon {
    font-size: 38px;
    line-height: 34px;
}
nav.navbar-default .nav > li > a,
nav.navbar-subsite .nav > li > a {
    position: relative;
    display: block;
    padding: 15px 12px 13px 12px;
    outline: none;
    height: 100%;
    line-height: 1em;
    color: #fff;
}
nav.navbar-default .nav > li.search > a,
nav.navbar-subsite .nav > li.search > a {
    color: #000;
}
nav.navbar-default .nav > li > a.dropdown-toggle,
nav.navbar-subsite .nav > li > a.dropdown-toggle,
nav.navbar-subsite .nav > li > a.dropdown-toggle + .dropdown-menu .sub-menu li.menu-hover > .offcanvas-link,
nav.navbar-subsite .small-nav li > a,
nav.navbar-default .menu {
    font-family: "GothamBook", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
}
nav.navbar-default .dropdown-toggle .icon-text {
    font-family: "GothamBookMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
}
nav.navbar-default .nav > li,
nav.navbar-subsite .nav > li {
    line-height: 1.45em;
}

nav.navbar-subsite .nav > li > a.dropdown-toggle:hover,
nav.navbar-subsite .nav > li > a.dropdown-toggle:focus {
    color:#fff;
    background-color: transparent;
 }
nav.navbar-default .nav > li > a.dropdown-toggle:focus,
nav.navbar-subsite .nav > li > a.dropdown-toggle:focus { 
    border-bottom-color: #000; 
    outline: thin dotted;
    outline-offset: 0;
}
nav.navbar-default .nav > li.hamburger.open > a.dropdown-toggle,
nav.navbar-default .nav > li.hamburger.open > a.dropdown-toggle:hover,
nav.navbar-default .nav > li.hamburger.open > a.dropdown-toggle:focus {
    border-bottom: none;
}
nav.navbar-default .search .dropdown-menu-large,
nav.navbar-default .hamburger .dropdown-menu-large {
    top:0;
    width: 100%;
}
nav.navbar-default-subsite .report:hover {
    opacity: 0.6;
}
nav.navbar-default .search .navbar-toggle .glyphicon,
nav.navbar-default .hamburger .navbar-toggle .glyphicon,
nav.navbar-default-subsite .report .glyphicon { 
    display: block; 
    font-size: 42px;
    line-height: 24px;
}
nav.navbar-default-subsite .report .glyphicon { font-size: 24px; }
nav.navbar-default .dropdown-menu-large .dropdown-header {
  color: #428bca;
  font-size: 18px;
}
.nav-header-2,
.nav-header-3 { 
    margin-bottom: 10px; 
}
nav.navbar-subsite .dropdown-menu {
    border: none;
    background-color: #f4f4f4;
    border-radius: 0;
}
nav.navbar-subsite .dropdown-menu .icon-list {
    margin: 10px 0;
}
nav.navbar-default .icon-list li:before,
nav.navbar-subsite .icon-list li:before {
    top: 1px;
}
nav.navbar-default .dropdown-menu-large .right .dropdown-menu-content p a,
nav.navbar-default .icon-list li > a,
nav.navbar-subsite .icon-list li > a,
nav.navbar-subsite .small-nav li > a,
nav.navbar-subsite .navbar-collapse ul.navbar-nav li.dropdown.open .icon-list li > a {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

/*Subsite specific*/

nav.navbar-default-subsite { 
    background-color: #fff; 
    z-index: 1000; 
    border-radius:0;
}
nav.navbar-subsite { 
    border-bottom: none;
    min-height:45px;   
}
nav.navbar-default-subsite .navbar-right { width: auto; } 
nav.navbar-subsite.navbar-fixed-top {
    top: 80px;
}
nav.navbar-subsite .navbar-collapse {
    text-align:center;
    width:100%;
    background-color: #0B2D71;
    line-height: .8em;
    margin: 0;
    border-radius:unset;
    padding-top:0;
    top:0;
}
nav.navbar-default .nav a.onesearch-button {
    display: block;
    text-align: center;
    border: none;
    margin: 0;
    padding: 7px 10px;
}

nav.navbar-default .nav a.onesearch-button .glyphicon {
    font-size: 38px;
    line-height: 34px;
    display: block;
}

nav.navbar-default .nav a.onesearch-button .icon-text {
    font-size: 0.75em;
    line-height: 1em;
    font-weight: normal;
    font-family: "GothamBookMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

nav.navbar-default-subsite .nav > li > a:hover,
nav.navbar-default .navbar-toggle.dropdown-toggle:hover,
nav.navbar-default .onesearch-button:hover {
    opacity: 0.6;
}
nav.navbar-default .navbar-toggle.dropdown-toggle,
nav.navbar-default .onesearch-button,
nav.navbar-default-subsite .nav > li > .report
nav.navbar-default-subsite.navbar-zoomed,
nav.navbar-subsite.navbar-zoomed {
    position: relative;
    top: 0;
}

/* utility nav */
nav.navbar-subsite .utility-nav .dropdown-menu.show-xs {
    padding-top: 0;
    top: -23px;
    width: 100%;
    box-shadow: none;
}
nav.navbar-subsite .utility-nav .icon-list {
    margin: 0;
}
nav.navbar-subsite #navbar-hamburger-menu:focus {
    outline:none;
}
nav.navbar-subsite .utility-nav .utility-nav-top {
    /*background-color: #fff;*/
    height: 100px;
}
nav.navbar-subsite .utility-nav .utility-nav-top .close {
    float: none;
    text-align: center;
    padding-top: 30px;
    font-size: 34px;
    opacity: 1;
    width: 100px;
    height: 100%;
}
nav.navbar-subsite .utility-nav div.small-nav {
    text-align: left;
    margin-left: 0;
}
nav.navbar-subsite .utility-nav div.small-nav.small-nav-right li {
    list-style: none;
}

nav.navbar-subsite .utility-nav .small-nav h3, 
nav.navbar-subsite .utility-nav .small-nav li {
    text-align: left;
}
nav.navbar-subsite .utility-nav .small-nav li {
   margin: 0;
   padding: 0;
   font-size: 1.1em;
}
nav.navbar-subsite .utility-nav .small-nav .icon-list li a  {
    border: none;
    color: #000;
    padding: 10px 20px;
    display: block;
}
nav.navbar-subsite .utility-nav .small-nav .icon-list li a .glyphicon  {
    padding-right: 10px;
}
nav.navbar-subsite .utility-nav .small-nav .icon-list li:hover,
nav.navbar-subsite .utility-nav .small-nav .icon-list li a:hover {
    background-color: #686c67;
    color: #fff;
}
nav.navbar-subsite .utility-nav .small-nav h3  {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 1.4em;
    padding-left: 20px;
    padding-top: 15px;
}
nav.navbar-subsite .utility-nav .small-nav .hr-mobile-nav {
    margin: 10px 20px;
}
nav.nav-column h3.nav-level-2 a {
    color: #fff;
    text-decoration: none;
}
/* end added for redesign */

/* the following keyframes are being used for the top nav trasnsitions. They need to be outside of the media queries due to an IE 11 bug. */
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Firefox */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Safari and Chrome */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Opera */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    nav.navbar-default { min-height: 110px; }
    nav.navbar-default .navbar-mobile { display: none; }
    nav.navbar-default .col { padding-left: 15px; padding-right: 15px; }
    nav.navbar-subsite { 
        z-index: 1101; 
    }
    nav.navbar-default .navbar-toggle.dropdown-toggle,
    nav.navbar-default .onesearch-button,
    nav.navbar-default-subsite .report {
        display: block;
        text-align: center;
        border: none;
        padding: 0;
        margin: 25px 0 0 0;
    }   
    nav.navbar-default-subsite .sub-title {
        font-size: 1.25em;
    }
    nav.navbar-default .hallmark-container {
        min-height: 46px;
    }   
    nav.navbar-default .sitename-container {
        height: 45px;
        min-height: 45px;
    }   
    nav.navbar-default .navbar-brand-centered a.sitename,
    nav.navbar-subsite .navbar-brand-centered a.sitename {
        font-size: 1.1em;
        line-height: 1.1em;
    }
    nav.navbar-default .navbar-brand-centered,
    nav.navbar-subsite .navbar-brand-centered {
        top: 16px;
        min-height:94px;
    }
    nav.navbar-default .navbar-right {
        text-align: right;
        /*margin-right: -95px;*/ 
    }
    nav.navbar-default .navbar-left > li, 
    nav.navbar-default .navbar-right {
        float: none
    }
    nav.navbar-subsite .sub-menu{
        min-height: 0 !important;
    }
    nav.navbar-subsite .navbar-collapse ul.navbar-nav {
        display: flex;
        justify-content: center;
        margin: 0 20px;
        width:100%
    }
    nav.navbar-subsite .navbar-collapse ul.navbar-nav > li.dropdown {
        float:none;
        display:inline-block;
        text-align:center;
    }
    nav.navbar-subsite .navbar-collapse ul.navbar-nav > li.dropdown:hover,
    nav.navbar-subsite .navbar-collapse ul.navbar-nav > li.dropdown:focus,
    nav.navbar-subsite .navbar-collapse ul.navbar-nav > li.dropdown:active, 
    nav.navbar-subsite .navbar-collapse ul.navbar-nav li.dropdown.open a {
        background-color: #0066b2;
    }  
    nav.navbar-subsite .navbar-collapse ul.navbar-nav > li.dropdown:active {
        background-color: #0066b2;
    } 
    nav.navbar-subsite li.dropdown.open a {
        background-color: transparent;
    }
    nav.navbar-subsite li.dropdown.open {
        background-color: #0066b2;
    } 
    nav.navbar-subsite .navbar-collapse ul.navbar-nav > li.dropdown:focus {
        background-color: #0066b2;
        outline: thin dotted;
        outline-offset: 0;
    }
    nav.navbar-default .navbar-toggle:hover, 
    nav.navbar-default .navbar-toggle:focus,
    nav.navbar-default .nav > li.search > a.dropdown-toggle:focus {
        background-color: transparent;
        opacity: 1;
    }
    nav.navbar-default .navbar-toggle:hover {
        background-color: transparent;
    }
    nav.navbar-default .nav > li.search > a.dropdown-toggle:focus,
    nav.navbar-subsite .navbar-collapse ul.navbar-nav > li.dropdown:focus {
        outline: thin dotted;
        outline-offset: 0;
    }
    nav.navbar-subsite .navbar-collapse ul.navbar-nav > li.dropdown:first-child {
        margin-left: 0;
    }
    nav.navbar-subsite .navbar-collapse ul.navbar-nav > li.dropdown:last-child {
        margin-right: 0;
    }
    nav.navbar-subsite .dropdown-menu {
        min-width: 320px;
    }
    nav.navbar-subsite li.dropdown .dropdown-menu{ 
        display: block; 
        visibility: hidden; 
        overflow-y: hidden; 
        max-height: 0; 
        transition: all .25s ease; 
        background-color: #0066b2;
    }

    nav.navbar-subsite li.dropdown:hover .dropdown-menu,
    nav.navbar-subsite li.dropdown.open .dropdown-menu { 
        display: block; 
        visibility: visible; 
        overflow-y: hidden; 
        max-height: 800px; 
        transition: all .5s ease; 
    }    
    nav.navbar-subsite li.dropdown.open > a:hover, 
    nav.navbar-subsite li.dropdown.open > a:focus,
    nav.navbar-subsite li.dropdown.open > a, 
    nav.navbar-subsite li.dropdown.open > a:active, 
    .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
        background-color: #0066b2;
    }
    nav.navbar-subsite li.dropdown.open > a {
        background-color: #0B2D71;
    }
    nav.navbar-default .mobile-nav-backdrop,
    nav.navbar-subsite .mobile-nav-backdrop {
        display:none;
    }
    nav.navbar-subsite .utility-nav .dropdown-menu.show-xs {
        margin-top: 0;
        border: none;
        /*for single column*/
        min-width: 320px;
        /* for double column */
        border-radius: 0;
        background-color: transparent;
    }
    nav.navbar-subsite .utility-nav .small-nav-container {
        padding-bottom:20px;
        background-color: #ededee;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	    position: relative;
    }
    nav.navbar-subsite .utility-nav .small-nav-container:after,     
    nav.navbar-subsite .utility-nav .small-nav-container:before {
	    bottom: 100%;
	    left:34px;
	    border: solid transparent;
	    content: " ";
	    height: 0;
	    width: 0;
	    position: absolute;
	    pointer-events: none;
    }
    nav.navbar-subsite .utility-nav .small-nav-container:after {
	    border-bottom-color: #ededee;
	    border-width: 16px;
	    margin-left: -16px;
    }
    nav.navbar-subsite .utility-nav .small-nav h3 {
        padding-left:30px;
    }
    nav.navbar-subsite .utility-nav .small-nav .icon-list li a  {
        padding: 10px 30px;
    }
    nav.navbar-subsite .utility-nav .small-nav .hr-mobile-nav {
        margin: 10px 30px;
    }
    nav.navbar-default .navbar-toggle.dropdown-toggle,
    nav.navbar-default .onesearch-button,
    nav.navbar-default-subsite .nav > li > .report {
        padding: 7px 10px;
        margin-top: 0;
    }    
    nav.navbar-default a.dropdown-toggle,
    nav.navbar-default .menu { 
        font-size: 0.75em;
        line-height: 1.17em;
    }
    nav.navbar-subsite .sub-menu .offcanvas-link.vertical-align {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    /* overwriting font-family for top nav */
    nav.navbar-subsite .nav > li > a.dropdown-toggle,    
    nav.navbar-subsite .nav > li > a.dropdown-toggle + .dropdown-menu .sub-menu li.menu-hover > .offcanvas-link {
        font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    nav.navbar-default .navbar-collapse { padding-right: 80px; }
    nav.navbar-default .nav > li > a { margin-left: 10px; margin-right: 10px; }
}
@media (min-width: 1010px) {
    nav.navbar-default .navbar-collapse { padding-right: 95px; }
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    nav.navbar-default .nav > li > a { margin-left: 20px; margin-right: 20px; }
}

/*  Small and below */
@media (max-width: 1199px) {
    nav.navbar-default .dropdown-menu .dropdown-menu-content h2, 
    nav.navbar-default .dropdown-menu .dropdown-menu-content .nav-header-2 { font-size: 2.2em; }
    nav.navbar-default .search-container .close { margin-right: 26px; }
}
/*  Small and below */
@media (max-width: 991px) {
    nav.navbar-default-subsite .search .onesearch-button {
        padding: 7px 10px;
        margin-top: 0;
    }
    nav.navbar-default-subsite .search .onesearch-button .visible-sm { display: none !important; }
    nav.navbar-default-subsite .search .onesearch-button .hidden-sm { display: block !important; }
    nav.navbar-default .dropdown-menu .dropdown-menu-content h2,
    nav.navbar-default .dropdown-menu .dropdown-menu-content .nav-header-2 { font-size: 1.8em; }
    nav.navbar-default .dropdown-menu .left .dropdown-menu-content { padding: 0 20px; }
    nav.navbar-default .hamburger-content { max-width: 600px; }
    nav.navbar-default .hamburger-content .col:nth-child(odd) { border-left: none; }
    nav.navbar-default .hamburger-content .bottom-links li:last-child { display: block; }    
    nav.navbar-default .search-container .close { margin: 24px 13px 20px 10px; }
}   
/* Extra Small and below */
@media (max-width: 767px) {
    nav.navbar-subsite .dropdown-menu.show-xs {
        position: static;
        display: block;
        background: none;
        background-color: transparent;
        }
    nav.navbar-default-subsite { z-index: 1001; }
    .navbar-default .navbar-toggle:hover, 
    .navbar-default .navbar-toggle:focus {
        background: none;
    }
    nav.navbar-default-subsite,
    nav.navbar-subsite {
        position:relative;
        top: 0;
    }
    nav.navbar-subsite {
        position:absolute;
        width: 100%;
    }
    nav.navbar-default .dropdown-mobile,
    nav.navbar-default-subsite .collapse-mobile,
    nav.navbar-default .navbar-mobile { width: 100%; }
    nav.navbar-default .dropdown-menu-large {
        margin-left: 0;
        margin-right: 0;
    }
    nav.navbar-default .search-mobile {
        position: absolute;
        right: 0;
        top: 0;
        text-align: right;
        width:auto;
    }
    nav.navbar-default .search-mobile .dropdown-toggle { 
        display: block; 
        padding-top: 21px;
        padding-bottom: 18px;
        padding-left: 15px;
    }
    nav.navbar-default .hamburger-mobile .dropdown-backdrop {
        display:none;
    }
    nav.navbar-default .hamburger-mobile > .dropdown-toggle,
    nav.navbar-default-subsite .hamburger-mobile > .collapse-toggle {        
        margin:0;
        padding: 33px 15px 24px 7px;
    }
    nav.navbar-default .hamburger-mobile > .dropdown-toggle:focus,
    nav.navbar-default-subsite .hamburger-mobile > .collapse-toggle:focus {
        outline-offset: -2px;
    }
    nav.navbar-default .hamburger-mobile > .dropdown-toggle:hover,
    nav.navbar-default .hamburger-mobile > .dropdown-toggle:active,
    nav.navbar-default .hamburger-mobile > .dropdown-toggle:visited,
    nav.navbar-default .search-mobile > .dropdown-toggle:hover,
    nav.navbar-default .search-mobile > .dropdown-toggle:active,
    nav.navbar-default .search-mobile > .dropdown-toggle:visited {
        outline: none;
    }
    nav.navbar-default .hamburger-mobile.open > .dropdown-toggle,
    nav.navbar-default-subsite .hamburger-mobile > .collapse-toggle:not(.collapsed),
    nav.navbar-default .search-mobile.open > .dropdown-toggle {
        background-color: #f4f4f4;
    }    
    nav.navbar-default .hamburger-mobile.open > .dropdown-toggle .glyphicon-menu-hamburger,
    nav.navbar-default-subsite .hamburger-mobile > .collapse-toggle:not(.collapsed) .glyphicon-menu-hamburger,
    nav.navbar-default .search-mobile.open > .dropdown-toggle .glyphicon-search {
        width:35.25px;
    }
    nav.navbar-default .hamburger-mobile.open span.glyphicon.glyphicon-menu-hamburger:before,
    nav.navbar-default-subsite .hamburger-mobile .collapse-toggle:not(.collapsed) span.glyphicon.glyphicon-menu-hamburger:before,
    nav.navbar-default .search-mobile.open .dropdown-toggle > span.glyphicon.glyphicon-search:before  {
        content: "\e60e";
    }
    nav.navbar-default .dropdown-menu-large > li {
        margin-bottom: 30px;
    }
    nav.navbar-default .dropdown-menu-large > li:last-child {
        margin-bottom: 0;
    }
    nav.navbar-default .dropdown-menu-large .dropdown-header {
        padding: 3px 15px !important;
    }

    nav.navbar-default .navbar-header {
        margin: 0 auto;
        float: none;
    }

    nav.navbar-default .navbar-mobile,
    nav.navbar-subsite .navbar-mobile,
    nav.navbar-subsite .navbar-mobile.navbar-collapse,
    nav.navbar-subsite .navbar-collapse {
        margin-top: 0;
        top:80px;
        border: none;
        border-radius:0;
        padding-top:0;
        padding-bottom: 0;
        background-color: transparent;
    }
    nav.navbar-subsite .navbar-mobile.navbar-collapse { 
        top:0;
        padding: 0; 
        max-height:none;
        overflow-y: visible;
    }
    nav.navbar-subsite .navbar-mobile.navbar-collapse.open { display: block; }
    nav.navbar-default .mobile-nav-container,
    nav.navbar-subsite .mobile-nav-container {
        float: left;
        background-color: #fff #f4f4f4;
        padding-bottom: 10px; 
        border-bottom: 10px solid #0b2d71;
    }
    nav.navbar-subsite .mobile-nav-container {
        display: block;
        width: 100%;
    }
    nav.navbar-default .mobile-nav-backdrop,
    nav.navbar-subsite .mobile-nav-backdrop {
        background-color: #f4f4f4;
        background-color: rgba(0,0,0,0.5);
    }
    nav.navbar-default .navbar-mobile hr {
        margin: 10px 20px;
        height: 3px;
    }
    nav.navbar-default .navbar-mobile hr.bottom-divider {
        margin: 0;
        height: 40px;
    }

    nav.navbar-default .navbar-mobile .navbar-nav,
    nav.navbar-subsite .navbar-mobile .navbar-nav {
        width: auto;
        font-size: 1.3em;
        /*removed 10/14/15 text-transform: lowercase;*/
        margin: 0;
    }
    nav.navbar-subsite .navbar-nav.primary {
        margin-top: 104px;
        width: 100%;
    }
    nav.navbar-default .navbar-nav.secondary,
    nav.navbar-subsite .navbar-nav.secondary {
        font-size: 1.3em;
        line-height: 1.2em;
        margin-top: 0;
    }
    nav.navbar-default .navbar-header .navbar-toggle {
        border: none;
       /* margin-top: 54px; */
        margin-left: 10px;
    }

    nav.navbar-default .navbar-header .navbar-toggle .icon-bar {
        height: 4px;
        width: 25px;
        background-color: #969696;
    }

    nav.navbar-default .navbar-header .navbar-toggle .icon-bar + .icon-bar {
        margin-top: 7px;
    }
    /*nav.navbar-default .navbar-header*/ .dropdown-toggle .glyphicon,
    /*nav.navbar-default-subsite .navbar-header*/ .collapse-toggle .glyphicon {
        font-size: 42px;
    }
    nav.navbar-default .navbar-header .close,
    nav.navbar-subsite .close {
        margin-top: 17px;
        margin-right: 15px;
        opacity: 1;
        font-size:21px;
    }
    nav.navbar-default .dropdown-menu-large .right .dropdown-menu-content p a,
    nav.navbar-default .icon-list li a,
    nav.navbar-subsite .icon-list li a {
        font-weight: 100;
    }
    nav.navbar-default .navbar-header .close .glyphicon,
    nav.navbar-subsite .close .glyphicon { font-size:42px; }

    nav.navbar-default .navbar-header .close:hover,
    nav.navbar-default .navbar-header .close:focus,
    nav.navbar-subsite .close:hover,
    nav.navbar-subsite .close:focus { opacity: 0.4; }

    nav.navbar-default .search-mobile .dropdown-toggle .glyphicon,
    nav.navbar-default .search-mobile .dropdown-menu .close .glyphicon,
    nav.navbar-subsite .search-mobile .dropdown-toggle .glyphicon,
    nav.navbar-subsite .search-mobile .dropdown-menu .close .glyphicon {
        margin-top: 5px;
        margin-right: 5px;
        font-size:54px;
    }    
    nav.navbar-default .navbar-header .search-mobile .dropdown-menu .close .glyphicon,
    nav.navbar-subsite .navbar-header .search-mobile .dropdown-menu .close .glyphicon {
        margin-top: 18px;
    }
    nav.navbar-default .navbar-mobile .navbar-nav > div,
    nav.navbar-subsite .navbar-mobile .navbar-nav > li {
        height: 100%;
        margin: 0;
        padding: 0;
        line-height: 2.7em;
        position: static;
        text-align:left;
    }
    nav.navbar-default .navbar-mobile .navbar-nav.secondary > div { padding: 23px 20px; }
    
    nav.navbar-default .navbar-mobile div a,
    nav.navbar-subsite .navbar-mobile li a {
        border: none;
        color: #000;
    }       
    nav.navbar-subsite .navbar-mobile .navbar-nav > li.menu-hover > a {
        display: block -webkit-box -webkit-flex -ms-flexbox flex;
        position: static;
        padding: 10px 20px;
        margin:0;
        border: none !important;
        
    }
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover { cursor: pointer; }
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover:hover,
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover:focus,
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover:active,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover:hover,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover:focus,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover:active  { background-color: #686c67; }
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover:hover > a,
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover:focus > a,
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover:active > a,
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover > a:hover,
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover > a:focus,
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover > a:active, 
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover:hover > a,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover:focus > a,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover:active > a,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover > a:hover,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover > a:focus,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover > a:active,  
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header:hover > a,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header:focus > a,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header:active > a,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header > a:hover,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header > a:focus,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header > a:active, 
    nav.navbar-subsite .navbar-mobile .navbar-nav .sub-menu .menu-hover:hover,
    nav.navbar-subsite .navbar-mobile .navbar-nav .sub-menu .menu-hover:focus,
    nav.navbar-subsite .navbar-mobile .navbar-nav .sub-menu .menu-hover:active
    {
        color: #fff; 
        text-decoration: none;
        background-color: #686c67;
    }
    /*nav.navbar-default .navbar-mobile .navbar-nav div.menu-hover:active { background: none; }*/
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover:active > a,
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover:active > a:active,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover:active > a,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover:active > a:active,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header:active > a,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header:active > a:active {
        text-decoration: none;   
     }
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover > a > .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover > a > .link-text {
        width: 90%;
        line-height: 1.65em;
        font-weight: normal;
    }
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover > a.offcanvas-link > .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover > a.offcanvas-link > .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header > a.offcanvas-link > .link-text  {
        display:block;
        width: 100%;    
    }
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover > a.offcanvas-link,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover > a.offcanvas-link,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header > a.offcanvas-link
    {
        justify-content: flex-start;
        -webkit-box-pack: start;
        width: 100%;
    }
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover > a.offcanvas-link:after,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover > a.offcanvas-link:after,
    nav.navbar-subsite .navbar-mobile .navbar-nav .nav-header > a.offcanvas-link:after {
        font-family: "GothamBook", "Helvetica Neue", Helvetica, Arial, sans-serif;
        content: '\203a';
        speak: none;
        font-size: 1em;
        margin-left: 10px;
        font-weight: 100 !important; 
    }
    nav.navbar-default .navbar-mobile .navbar-nav .menu-hover > a > .glyphicon,
    nav.navbar-subsite .navbar-mobile .navbar-nav .menu-hover > a > .glyphicon {
        display:inline-block;
        line-height: 1.25em;
        top: 8px;
    }
    nav.navbar-default .navbar-mobile .navbar-nav .sub-menu .menu-hover > a > .glyphicon,
    nav.navbar-subsite .navbar-mobile .navbar-nav .sub-menu .menu-hover > a > .glyphicon {  top: -1px;  }

    nav.navbar-default .navbar-mobile .navbar-nav .secondary,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary { 
        text-align: left; 
        padding: 14px 20px;
        line-height: 1.65em;
    }    
    nav.navbar-default .navbar-mobile .navbar-nav .secondary > a,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary > a { 
        display:block;
        padding: 8px 0;
    }
    nav.navbar-default .navbar-mobile .navbar-nav .secondary .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary .link-text { 
        margin-left: 10px;
        color: #000; 
    }
    nav.navbar-default .navbar-mobile .navbar-nav .secondary:hover .link-text,
    nav.navbar-default .navbar-mobile .navbar-nav .secondary:focus .link-text,
    nav.navbar-default .navbar-mobile .navbar-nav .secondary:active .link-text,
    nav.navbar-default .navbar-mobile .navbar-nav .secondary > a:hover .link-text,
    nav.navbar-default .navbar-mobile .navbar-nav .secondary > a:focus .link-text,
    nav.navbar-default .navbar-mobile .navbar-nav .secondary > a:active .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary:hover .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary:focus .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary:active .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary > a:hover .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary > a:focus .link-text,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary > a:active .link-text { color: #fff; }

    nav.navbar-default .navbar-mobile .navbar-nav .secondary .glyphicon,
    nav.navbar-subsite .navbar-mobile .navbar-nav .secondary .glyphicon { 
        font-size: .5em; 
        top: -1px;

    }
    /*Mobile Search*/
    nav.navbar-default .navbar-mobile a span.glyphicon,
    nav.navbar-subsite .navbar-mobile a span.glyphicon {
        top: 3px;
    }
    nav.navbar-default .search-container,
    nav.navbar-subsite .search-container {
        position: relative;
        margin:0;
        border: none;
        width:100%;
    }
    nav.navbar-default .search-container .close,
    nav.navbar-subsite .search-container .close {
        margin: 0;
    }
    nav.navbar-subsite div.small-nav-corp-links {
        margin-top: 20px;
    }
    nav.navbar-default .hamburger-mobile.open>.dropdown-toggle, nav.navbar-default-subsite .hamburger-mobile>.collapse-toggle:not(.collapsed), .search-mobile.open>.dropdown-toggle {
        background-color: #f4f4f4;
    }
    /*
 * Off Canvas Sub Nav
 * --------------------------------------------------
 */
    nav.navbar-subsite .dropdown-menu {
        left:auto;
        box-shadow: none;
    }
    nav.navbar-subsite .offcanvas .dropdown-menu,
    nav.navbar-subsite .sub-menu {
        font-size: inherit;
    }
    nav.navbar-default .row-offcanvas,
    nav.navbar-subsite .row-offcanvas {
        position: absolute;
        top: 80px;
        -webkit-transition: all .25s ease-out;
        -moz-transition: all .25s ease-out;
        transition: all .25s ease-out;
        margin: 0;
        width: 100%;
        display: none;
    }

    nav.navbar-default .row-offcanvas.active,
    nav.navbar-subsite .row-offcanvas.active {
        display: block;
    }

    nav.navbar-default .row-offcanvas-right,
    nav.navbar-subsite .row-offcanvas-right {
        right: -15px;
    }

    nav.navbar-default .row-offcanvas-left,
    nav.navbar-subsite .row-offcanvas-left {
        left: -15px;
    }

    nav.navbar-default .row-offcanvas-right .sidebar-offcanvas,
    nav.navbar-subsite .row-offcanvas-right .sidebar-offcanvas {
        right: -100%; /* 12 columns */
    }

    nav.navbar-default .row-offcanvas-left .sidebar-offcanvas,
    nav.navbar-subsite .row-offcanvas-left .sidebar-offcanvas {
        left: -100%; /* 12 columns */
    }


   

    nav.navbar-default .row-offcanvas-left.active,
    nav.navbar-subsite .row-offcanvas-left.active {
        left: 100%; /* 12 columns */
    }
    
    nav.navbar-default .level-2,
    nav.navbar-subsite .level-2 {
        position: static;
    }

    nav.navbar-default .level-2 .row-offcanvas-right,
    nav.navbar-subsite .level-2 .row-offcanvas-right {
        top: -30px;
    }
    nav.navbar-default .sidebar-offcanvas,
    nav.navbar-subsite .sidebar-offcanvas {
        position: absolute;
        top: 24px;
        width: 100%; /* 12 columns */
        height: auto;
        background-color: #fff;
        padding: 0;
        z-index: 1000;
        overflow: hidden;
    }
    nav.navbar-subsite .dropdown-menu > .sidebar-offcanvas {
        margin:0;
    }

    nav.navbar-default .navbar-mobile .row-offcanvas .main-menu-link,
    nav.navbar-subsite .navbar-mobile .row-offcanvas .main-menu-link {
        width:100%;
        font-size:14px;
        background-color: #f4f4f4;
    }
    nav.navbar-default .navbar-mobile .row-offcanvas .main-menu-link .glyphicon,
    nav.navbar-subsite .navbar-mobile .row-offcanvas .main-menu-link .glyphicon {
        padding-right: 5px;
        font-size:18px;
        top: 0;
        font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif
    }    
    nav.navbar-default .navbar-mobile .row-offcanvas .main-menu-link .glyphicon-chevron-left:before,
    nav.navbar-subsite .navbar-mobile .row-offcanvas .main-menu-link .glyphicon-chevron-left:before {
        content: "\2039";
    }
    nav.navbar-default .navbar-mobile .row-offcanvas hr,
    nav.navbar-subsite .navbar-mobile .row-offcanvas hr {
        margin: 0 20px 10px 20px;
    }

    nav.navbar-default .navbar-mobile .row-offcanvas hr.bottom-divider,
    nav.navbar-subsite .navbar-mobile .row-offcanvas hr.bottom-divider {
        margin: 0;
    }

    nav.navbar-default .sidebar-offcanvas .sub-menu,
    nav.navbar-subsite .sidebar-offcanvas .sub-menu {
        margin: 0;
        background-color: #f4f4f4;
    }

    nav.navbar-default .sidebar-offcanvas .sub-menu h2,
    nav.navbar-default .sidebar-offcanvas .sub-menu h3,
    nav.navbar-default .sidebar-offcanvas .sub-menu .nav-header-2,
    nav.navbar-default .sidebar-offcanvas .sub-menu .nav-header-3,
    nav.navbar-subsite .sidebar-offcanvas .sub-menu h2,
    nav.navbar-subsite .sidebar-offcanvas .sub-menu h3,
    nav.navbar-subsite .sidebar-offcanvas .sub-menu .nav-header
    {
        text-align: left;
        font-size: 1em;
        margin-left: 15px;
    }
    nav.navbar-default .sidebar-offcanvas .sub-menu h2:first-child,
    nav.navbar-subsite .sidebar-offcanvas .sub-menu h2:first-child {
        margin-top:0;
        padding-top: 10px;
    }    
    nav.navbar-subsite .sidebar-offcanvas .sub-menu .nav-header {
        padding:0;
        margin: 0;
    }
    nav.navbar-subsite .sidebar-offcanvas .sub-menu .nav-header > a
    {        
        padding: 10px 20px;
        line-height: 1.65em;
    }
    nav.navbar-default .sidebar-offcanvas .sub-menu h3,
    nav.navbar-subsite .sidebar-offcanvas .sub-menu h3  {
        font-size: 1em;
        line-height: 1.35em;
        font-weight: 700;
        margin-top: 40px;
    }

    nav.navbar-default .sidebar-offcanvas .sub-menu .icon-list
    {
        font-size: .7em;
        margin-top: 30px;
    }
    nav.navbar-subsite .sidebar-offcanvas .sub-menu .icon-list {
        margin: 0;
    }
    nav.navbar-default .sidebar-offcanvas .sub-menu .icon-list li,
    nav.navbar-subsite .sidebar-offcanvas .sub-menu .icon-list li {
        padding: 0;
        margin: 0;
    }
    nav.navbar-default .sidebar-offcanvas .sub-menu .icon-list li:before {
        top:15px;
        left: 20px;
    }
    nav.navbar-subsite .sidebar-offcanvas .sub-menu .icon-list li:before {
        top: 26px;
        left: 34px;
        font-size: 0.5em;
    }
    nav.navbar-default .sidebar-offcanvas .sub-menu .icon-list li > a,
    nav.navbar-subsite .sidebar-offcanvas .sub-menu .icon-list li > a {
        line-height: 1.4em;
    }
    nav.navbar-subsite .sidebar-offcanvas .sub-menu .icon-list li > a {
        padding: 10px 20px 10px 40px;
    }

    nav.navbar-default .navbar-mobile .main-menu-link a,
    nav.navbar-subsite .navbar-mobile .main-menu-link a,
    nav.navbar-subsite .navbar-mobile .sub-menu .main-menu-link a {
        width: 100%;
        padding: 7px 0 5px 15px;
        line-height: 2.7em;
        color: #000;
        font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
    }
    nav.navbar-default .navbar-mobile .main-menu-link a:hover,
    nav.navbar-default .navbar-mobile .main-menu-link a:focus,
    nav.navbar-default .navbar-mobile .main-menu-link a:active,
    nav.navbar-subsite .navbar-mobile .main-menu-link a:hover,
    nav.navbar-subsite .navbar-mobile .main-menu-link a:focus,
    nav.navbar-subsite .navbar-mobile .main-menu-link a:active {
        color: #fff;
        background-color: #686c67;
        text-decoration: none;
    }
}
    
/*Fix for CMS nav*/
#scPageExtendersForm ~ .container-fluid nav.navbar-default-subsite.navbar-fixed-top {
    top: 89px;
}
#scPageExtendersForm ~ .container-fluid nav.navbar-subsite.navbar-fixed-top {
    top: 169px;
}

/* print styles */
@media print {
    /* main nav */
    nav.navbar{display:block;}
    nav.navbar .navbar-header{visibility:hidden !important;}
    nav.navbar > .container-fluid .navbar-brand{visibility:visible !important;}
    nav.navbar-default .search-mobile { display: none; }
}


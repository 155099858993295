body .ui-dialog {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

    .ui-dialog-titlebar {
      padding: 1em;
      border-bottom: 1px solid #c7c7c7;

      .ui-dialog-title {
        margin: 0;
        float: none;
        font-size: 1.3em;
        font-weight: bold;
      }

      .ui-dialog-titlebar-icon {
        color: #767b7f;
        border: 0 none;
        -moz-transition: color 0.3s;
        -o-transition: color 0.3s;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
        padding: 0;

        &:hover {
          color: #2d353c;
        }
      }
    }

    .ui-dialog-content {
      padding: 0.85em;
      .ui-confirmdialog-message{
        line-height:auto;
      }
    }

    .ui-dialog-buttonpane {
      padding: 0.375em 0.75em;
      text-align: right;
    }
    
    &.ui-confirm-dialog .ui-dialog-content > span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1em;
      margin: 0;
    }
    .ui-dialog-footer{
      border-bottom-width: 0px;
    }
  }

  .ui-confirmdialog.ui-dialog .ui-dialog-content {
    padding: 0.7em 0.85em;
}